import moment from 'moment';
import * as Sentry from "@sentry/react";
Sentry.init({ dsn: "https://d2dd14ec78174be3980d9211b5ee4b57@o417959.ingest.sentry.io/5667296" });

export const getPublicUrl = (url) => {
    //Trim the URL of any Access Keys, the Folder is public
    try {
      const urlParts = url.split("?GoogleAccessId");
      return urlParts[0];
    } catch (err) {
      return url;
    }
  };

export const isEmail = value => {
  return value.indexOf("@") !== -1
}

export const toCurrency = (number) => {
  return `$${parseFloat(number).toLocaleString(undefined, {
      minimumFractionDigits: 2, maximumFractionDigits: 2
  })}`
}

export const sluggedName = (name) => {

  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  const sluggedNameFormat = name?.toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

    return sluggedNameFormat
}

export const getTodaysHours = (openingDays) => {
  const today = new Date();
  const day = moment(today).format("dddd").toString();

  //Get the store hours for said date
  const hoursIndex = openingDays?.findIndex((el) => el.day === day);

  const openingTimeObj = openingDays[hoursIndex].openingTime;
  const closingTimeObj = openingDays[hoursIndex].closingTime;
  const openToday = openingDays[hoursIndex].open;

  return { openingTimeObj, closingTimeObj, openToday };
};

export const getStoreOpeningAndClosing = (openingTimeObj, closingTimeObj) => {
  const openingTime = moment(
    (openingTimeObj._seconds ?? openingTimeObj.seconds) * 1000
  ).format("h:mmA");
  const closingTime = moment(
    (closingTimeObj._seconds ?? closingTimeObj.seconds) * 1000
  ).format("h:mmA");

  const now = moment();

  const date = now.format("YYYY-MM-DD");
  const storeOpenTime = moment(date + " " + openingTime, "YYYY-MM-DD h:mmA");
  const storeCloseTime = moment(date + " " + closingTime, "YYYY-MM-DD h:mmA");

  return { storeOpenTime, storeCloseTime };
};

export const _captureError = (e) => {
  Sentry.captureException(e);
}